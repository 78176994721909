<template>
  <section class="l-content">
    <el-card class="l-condition" name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="分组">
          <l-select v-model="condition.groupId" :data="groupList" :prop="['groupId', 'groupName']"></l-select>
        </el-form-item>
        <el-form-item label="来源">
          <l-select v-model="condition.carSource" :data="$constant.carSourceList"></l-select>
        </el-form-item>
        <el-form-item label="车辆状态">
          <l-select v-model="condition.carStatus" :data="$constant.carStatusList"></l-select>
        </el-form-item>
        <el-form-item label="使用状态">
          <l-select v-model="condition.useStatus" :data="$constant.useStatusList"></l-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" size="small" placeholder="车牌号/车架号"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
        </el-form-item>
        <el-form-item class="fr">
          <el-button type="warning" size="small" @click="columnSetting()">栏目</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card class="overflow" v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="carNo" slot-scope="scope">
          <el-button type="text" @click="$site.view_car_detail(scope.row.carId)">{{ scope.row.carNo }}</el-button>
        </template>
        <template slot="insuranceJQXExpireDate" slot-scope="scope">
          <span v-if="scope.row.insuranceJQXState == 1" class="text-green">{{ scope.row.insuranceJQXExpireDate | limitTo(10) }}</span>
          <span v-else-if="scope.row.insuranceJQXState == 2" class="text-yellow">{{ scope.row.insuranceJQXExpireDate | limitTo(10) }}</span>
          <span v-else-if="scope.row.insuranceJQXState == 3" class="text-red">{{ scope.row.insuranceJQXExpireDate | limitTo(10) }}</span>
          <span v-else>{{ scope.row.insuranceJQXExpireDate | limitTo(10) }}</span>
        </template>
        <template slot="insuranceSYXExpireDate" slot-scope="scope">
          <span v-if="scope.row.insuranceSYXState == 1" class="text-green">{{ scope.row.insuranceSYXExpireDate | limitTo(10) }}</span>
          <span v-else-if="scope.row.insuranceSYXState == 2" class="text-yellow">{{ scope.row.insuranceSYXExpireDate | limitTo(10) }}</span>
          <span v-else-if="scope.row.insuranceSYXState == 3" class="text-red">{{ scope.row.insuranceSYXExpireDate | limitTo(10) }}</span>
          <span v-else>{{ scope.row.insuranceSYXExpireDate | limitTo(10) }}</span>
        </template>
        <template slot="yearlyInspectExpireDate" slot-scope="scope">
          <span v-if="scope.row.yearlyInspectState == 1" class="text-green">{{ scope.row.yearlyInspectExpireDate | limitTo(10) }}</span>
          <span v-else-if="scope.row.yearlyInspectState == 2" class="text-yellow">{{ scope.row.yearlyInspectExpireDate | limitTo(10) }}</span>
          <span v-else-if="scope.row.yearlyInspectState == 3" class="text-red">{{ scope.row.yearlyInspectExpireDate | limitTo(10) }}</span>
          <span v-else>{{ scope.row.yearlyInspectExpireDate | limitTo(10) }}</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import carAddoredit from './car_addoredit'
import carColumn from './car_column'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'CarList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '车牌号', slot: 'carNo' },
          { label: 'VIN码', prop: 'vin', overflow: true }
          // { label: '品牌型号', prop: 'modelName', overflow: true },
          // { label: '车辆来源', prop: 'carSourceText' },
          // { label: '车辆状态', prop: 'carStatusText' },
          // { label: '保险(交)到期', prop: 'insuranceJQXExpireDate', filter: a => this.$lgh.limitTo(a, 10) },
          // { label: '年检到期', prop: 'yearlyInspectExpireDate', filter: a => this.$lgh.limitTo(a, 10) }
          // { label: '责任人', prop: 'reponsibleUserName' }
        ],
        operation: {
          width: '160',
          header: { type: 'warning', icon: 'el-icon-plus', size: 'mini', label: '新增', click: this.add },
          buttons: [{ type: 'primary', icon: 'el-icon-edit', label: '修改', click: this.edit }]
        }
      },
      condition: {
        keyword: '',
        groupId: '',
        carSource: '',
        carStatus: '',
        useStatus: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null,
      groupList: []
    }
  },
  created() {
    this.initColumn()
    this.getGroupList()
    this.getPageData()
  },
  methods: {
    initColumn() {
      var defaultArr = [
        { label: '序号', type: 'index' },
        { label: '车牌号', slot: 'carNo' },
        { label: 'VIN码', prop: 'vin', overflow: true }
      ]
      var arr = this.$constant.ecarListColumn.get(this.userInfo.enterpriseCode, this.userInfo.userId)
      arr.forEach((a) => {
        if (a.visible) {
          defaultArr.push(a)
        }
        this.tableOption.columns = defaultArr
      })
    },
    async getGroupList() {
      this.groupList = await this.$lgh.get('api/enterprise/ecar/group/list', ['enterpriseCode'])
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/ecar/list', ['enterpriseCode', this.condition])
    },
    async columnSetting() {
      await this.$lgh.openDialog({
        ins: carColumn,
        title: '列表栏目设置',
        data: {
          dataInfo: this.userInfo
        }
      })
      this.$lgh.toastSuccess('设置成功')

      this.initColumn()
    },
    async add() {
      await this.$lgh.openDialog({
        ins: carAddoredit,
        title: '新增车辆信息',
        data: {
          dataInfo: {
            enterpriseCode: this.userInfo.enterpriseCode,
            carNature: 0,
            carStatus: 0
          }
        }
      })
      this.$lgh.toastSuccess('新增车辆信息成功')

      this.getPageData()
    },
    async edit(item) {
      await this.$lgh.openDialog({
        ins: carAddoredit,
        title: '修改车辆信息',
        data: {
          dataInfo: Object.assign({}, item)
        }
      })
      this.$lgh.toastSuccess('修改车辆信息成功')
      this.getPageData()
    }
  }
}
</script>
