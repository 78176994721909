<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh" width="80%">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-row class="padding">
        <el-col class="padding-right-xs">
          <el-row class="twice">
            <el-form-item label="车牌号" prop="carNo">
              <el-input v-model="dataInfo.carNo" autocomplete="off" :disabled="dataInfo.carId != null"></el-input>
            </el-form-item>
            <!-- <el-form-item label="品牌/车系">
                <el-button type="text">
                  <span class="text-bold" v-if="dataInfo.carBrandInfo">{{ dataInfo.carBrandInfo.brandName }}-{{ dataInfo.carSeriesInfo.seriesName }}</span>
                  <span class="text-bold" v-else>选择品牌</span>
                </el-button>
              </el-form-item> -->
            <el-form-item label="品牌型号" prop="modelName">
              <el-input v-model="dataInfo.modelName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="VIN码" prop="vin">
              <el-input v-model="dataInfo.vin" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="发动机号" prop="engineNo">
              <el-input v-model="dataInfo.engineNo" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="车辆来源" prop="carSource">
              <l-select v-model="dataInfo.carSource" :all="false" :data="$constant.carSourceList"></l-select>
            </el-form-item>
            <el-form-item label="车辆状态" prop="carStatus">
              <l-select v-model="dataInfo.carStatus" :all="false" :data="$constant.carStatusList"></l-select>
            </el-form-item>
            <el-form-item label="当前里程数" prop="mileage">
              <el-input v-model="dataInfo.mileage" type="number" autocomplete="off">
                <span slot="suffix">公里</span>
              </el-input>
            </el-form-item>
            <el-form-item label="注册日期" prop="registerDate">
              <l-datepicker v-model="dataInfo.registerDate"></l-datepicker>
            </el-form-item>
            <el-form-item label="责任人" prop="reponsibleUserName">
              <p>
                <span class="text-blue margin-right">{{ dataInfo.reponsibleUserName }}</span>
                <el-button type="warning" @click="setReponsibleUser()" icon="el-icon-edit" circle size="mini"></el-button>
              </p>
            </el-form-item>
            <el-form-item label="检查员" prop="inspectUserName">
              <p>
                <span class="text-blue margin-right">{{ dataInfo.inspectUserName }}</span>
                <el-button type="warning" @click="setInspectUser()" icon="el-icon-edit" circle size="mini"></el-button>
              </p>
            </el-form-item>
            <el-form-item label="检查方式" prop="inspectType">
              <el-radio-group v-model="dataInfo.inspectType">
                <el-radio-button :label="0">不定期</el-radio-button>
                <el-radio-button :label="1">定期检查</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="检查周期" prop="inspectDuring">
              <el-input v-model="dataInfo.inspectDuring" type="number" autocomplete="off" style="width:100px;">
                <span slot="suffix">天</span>
              </el-input>
            </el-form-item>
            <el-form-item label="电子围栏" prop="fenceId">
              <l-select v-model="dataInfo.fenceId" :all="true" :data="fenceList" :prop="['fenceId', 'fenceName']"></l-select>
            </el-form-item>
            <el-form-item label="车辆分组" prop="groupId">
              <l-select v-model="dataInfo.groupId" :all="true" :data="groupList" :prop="['groupId', 'groupName']"></l-select>
            </el-form-item>
            <el-form-item label="交强险到期" prop="insuranceJQXExpireDate">
              <p>{{ dataInfo.insuranceJQXExpireDate | limitTo(10) }}</p>
            </el-form-item>
            <el-form-item label="商业险到期" prop="insuranceSYXExpireDate">
              <p>{{ dataInfo.insuranceSYXExpireDate | limitTo(10) }}</p>
            </el-form-item>
            <el-form-item label="年检到期" prop="yearlyInspectExpireDate">
              <p>{{ dataInfo.yearlyInspectExpireDate | limitTo(10) }}</p>
            </el-form-item>
            <el-form-item label="保养到期" prop="maintenanceExpireDate">
              <p>{{ dataInfo.maintenanceExpireDate | limitTo(10) }}</p>
            </el-form-item>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      activeName: 'base',
      dataRules: {},
      fenceList: null,
      groupList: null
    }
  },
  mounted() {
    this.dataInfo.carSource = this.dataInfo.carSource.toString()
    this.dataInfo.carStatus = this.dataInfo.carStatus.toString()
    this.getFenceList()
    this.getGroupList()
  },
  methods: {
    getGroupList() {
      this.$lgh.get('api/enterprise/ecar/group/list', ['enterpriseCode']).then(res => {
        this.groupList = res
      })
    },
    getFenceList() {
      this.$lgh.get('api/enterprise/car/fence/list', ['enterpriseCode']).then(res => {
        this.fenceList = res
      })
    },
    setReponsibleUser() {
      this.$site.sel_enterpriseEmployee(this.dataInfo.enterpriseCode).then(user => {
        this.dataInfo.reponsibleUserId = user.userId
        this.dataInfo.reponsibleUserName = user.name
      })
    },
    setInspectUser() {
      this.$site.sel_enterpriseEmployee(this.dataInfo.enterpriseCode).then(user => {
        this.dataInfo.inspectUserId = user.userId
        this.dataInfo.inspectUserName = user.name
      })
    },
    save() {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          await this.$lgh.post('api/enterprise/ecar/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
